<template>
  <div class="box">
    <div class="p-d-flex p-flex-column p-ai-center">
      <img :src="img" />
      <p class="p-text-center">{{ emptyText }}</p>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    emptyText: {
      type: String,
      default: 'empty',
    },
    img: {
      type: String,
      default: '/mailbox.png',
    },
  },
};
</script>

<style scoped lang="scss">
.box {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 62vh;
  img {
    min-height: 50px;
    min-width: 50px;
    width: 150px;
    height: 150px;
    opacity: 0.8;
    border-radius: 20px;
  }
  p {
    // font-family: Sarabun;
    color: $primaryColor;
    margin-top: 14px;
    font-size: 1.15rem;
  }
}
</style>
