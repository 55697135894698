<template>
  <div v-if="feedbacks.length || loading" class="feedbacks-container">
    <div v-if="loading">
      <DataTable
        :value="tempArray"
        show-gridlines
        responsive-layout="scroll"
        class="feedback-table"
      >
        <Column header="User" class="w-15">
          <template #body="">
            <Skeleton height="3rem"></Skeleton>
          </template>
        </Column>
        <Column header="Feedback" class="w-40">
          <template #body="">
            <Skeleton height="3rem"></Skeleton>
          </template>
        </Column>
        <Column header="Agent details" class="w-30">
          <template #body="">
            <Skeleton height="3rem"></Skeleton>
          </template>
        </Column>
        <Column header="Actions" class="w-15">
          <template #body="">
            <Skeleton height="3rem"></Skeleton>
          </template>
        </Column>
      </DataTable>
    </div>
    <div v-else>
      <DataTable
        :value="feedbacks"
        show-gridlines
        responsive-layout="scroll"
        class="feedback-table"
      >
        <Column header="User" class="w-15">
          <template #body="props">
            <router-link :to="`/profile/${props.data.attributes.user_slug}`">
              {{ props.data.attributes.user_first_name }}
            </router-link>
          </template>
        </Column>
        <Column field="attributes.comment" header="Feedback" class="w-40">
          <template #body="props">
            <div>
              <span class="reply-feedback"
                >{{ props.data.attributes.feedback_type }} :
              </span>
              <span v-html="props.data.attributes.comment"></span>
            </div>
          </template>
        </Column>
        <Column
          field="attributes.agent_details"
          header="Agent details"
          class="w-30"
        >
          <template #body="props">
            <div class="p-mb-1">
              Sent from mobile app:
              {{ props.data.attributes.is_mobile_app ? 'Yes' : 'No' }}
            </div>
            <div class="p-mb-1">
              Sent from mobile browser:
              {{ props.data.attributes.is_mobile_browser ? 'Yes' : 'No' }}
            </div>
            <div class="p-mb-1">
              Platform: {{ props.data.attributes.platform_name }}, Version:
              {{ props.data.attributes.platform_version }}
            </div>
            <div v-if="props.data.attributes.is_mobile_app" class="p-mb-1">
              Device: {{ props.data.attributes.device_name }}, Brand:
              {{ props.data.attributes.device_brand }}
            </div>
            <div v-else class="p-mb-1">
              Browser: {{ props.data.attributes.browser_name }}, Version:
              {{ props.data.attributes.browser_version }}
            </div>
          </template>
        </Column>
        <Column header="Actions" class="w-15">
          <template #body="props">
            <Button
              v-tooltip.top="'Delete'"
              class="p-ml-2 p-mb-2"
              icon="pi pi-trash"
              @click="toggleDeleteDialog(props.data.attributes)"
            />
            <Button
              v-tooltip.top="'Reply'"
              class="p-ml-2 p-mb-2"
              icon="pi pi-send"
              @click="toggleReplyDialog(props.data.attributes)"
            />
          </template>
        </Column>
      </DataTable>
    </div>
    <Paginator
      :rows="30"
      :total-records="totalFeedbackCount"
      :always-show="false"
      @page="onPageChange($event)"
    ></Paginator>
    <Dialog
      v-model:visible="showDeleteDialog"
      :show-header="false"
      :modal="true"
      :closable="true"
      :dismissable-mask="true"
      :close-on-escape="true"
      class="feedback-delete-dialog"
      :draggable="false"
    >
      <div class="block-user-content">
        <div class="confirmation-text p-mb-4">
          Do you want to delete this Feedback?
        </div>
        <div class="confirmation-buttons">
          <Button
            label="Delete"
            class="p-button-primary confirmation-button p-button-sm p-mr-1"
            @click="deleteFeedback()"
          />
          <Button
            label="Cancel"
            class="
              p-button-primary p-button-outlined
              confirmation-button
              p-button-sm p-ml-1
            "
            @click="showDeleteDialog = false"
          />
        </div>
      </div>
    </Dialog>
    <Dialog
      v-model:visible="showReplyDialog"
      class="reply-dialog"
      :modal="true"
      :closable="true"
      :dismissable-mask="true"
      :close-on-escape="true"
      :draggable="false"
      @hide="clearReply()"
    >
      <template #header>
        <strong>{{ `Reply to ${selectedFeedback.user_email}` }}</strong>
      </template>
      <tiny-text-editor v-model="reply" :show-toolbar="true" />
      <div class="p-error">
        <small v-if="v$.$dirty && v$.reply.required.$invalid" class="p-error">
          Required
        </small>
      </div>
      <template #footer>
        <Button label="Reply" @click="replyFeedback()" />
      </template>
    </Dialog>
  </div>
  <empty-mails v-else :empty-text="'No feedbacks yet'" />
</template>

<script>
import useValidate from '@vuelidate/core';
import { required } from '@vuelidate/validators';
import EmptyMails from '@/components/EmptyMails.vue';
import TinyTextEditor from '@/components/TinyTextEditor';

export default {
  components: {
    EmptyMails,
    TinyTextEditor,
  },
  data() {
    return {
      tempArray: [[], [], [], []],
      loading: false,
      feedbacks: [],
      showConfirmationDialog: false,
      pageNo: 1,
      showDeleteDialog: false,
      showReplyDialog: false,
      reply: '',
      v$: useValidate(),
      selectedFeedback: {},
      totalFeedbackCount: 0,
    };
  },
  validations() {
    return {
      reply: { required },
    };
  },
  mounted() {
    this.fetchFeedbacks();
  },
  methods: {
    fetchFeedbacks() {
      let that = this;
      this.loading = true;
      this.$http
        .get('/feedbacks', { params: { page_no: that.pageNo } })
        .then((res) => {
          that.loading = false;
          that.feedbacks = res.data.feedbacks.data;
          that.totalFeedbackCount = res.data.total_feedback_count;
        });
    },
    deleteFeedback() {
      let that = this;
      this.$http.delete(`/feedbacks/${this.selectedFeedback.id}`).then((_) => {
        that.feedbacks.forEach((element, index) => {
          if (element.attributes.id === that.selectedFeedback.id) {
            that.feedbacks.splice(index, 1);
            that.$moshaToast('Feedback deleted successfully', {
              type: 'success',
              showIcon: true,
            });
            that.showDeleteDialog = false;
          }
        });
      });
    },
    replyFeedback() {
      this.v$.$touch();
      if (!this.v$.$error) {
        let that = this;
        this.$http
          .post(`/feedbacks/${this.selectedFeedback.id}/reply`, {
            reply: this.reply,
          })
          .then((_) => {
            that.$moshaToast('Reply sent successfully', {
              type: 'success',
              showIcon: true,
            });
            that.showReplyDialog = false;
            that.clearReply();
          });
      }
    },
    toggleDeleteDialog(feedback) {
      this.showDeleteDialog = true;
      this.selectedFeedback = feedback;
    },
    toggleReplyDialog(feedback) {
      this.showReplyDialog = true;
      this.selectedFeedback = feedback;
    },
    clearReply() {
      this.reply = '';
      this.v$.$reset();
    },
    onPageChange(e) {
      this.pageNo = e.page + 1;
      this.fetchFeedbacks();
    },
  },
};
</script>
<style lang="scss">
.feedbacks-container {
  .feedback-table {
    .w-40 {
      width: 40%;
      p {
        margin: 0 !important;
      }
      .reply-feedback {
        float: left;
        margin: 2px 2px 0 0;
      }
    }
    .w-30 {
      width: 30%;
    }
    .w-15 {
      width: 15%;
    }
  }
}
.feedback-delete-dialog {
  .p-dialog-content {
    position: relative;
    padding: 2rem;
    width: 20rem;
    max-width: 90vw;
  }
  .block-user-content {
    display: flex;
    flex-direction: column;
    .confirmation-text {
      text-align: center;
    }
    .confirmation-buttons {
      display: flex;
      align-items: center;
      justify-content: center;
      .confirmation-button {
        width: 5em;
      }
    }
  }
}
.reply-dialog {
  width: 40rem;
  max-width: 90%;
  textarea {
    min-height: 300px;
    width: 40rem;
    max-width: 90%;
  }
  .p-inputtext {
    resize: none;
  }
  .p-dialog-header {
    word-break: break-word;
  }
  .p-dialog-footer {
    padding: 0 1.5rem 1.5rem 1.5rem !important;
  }
  .p-dialog-content {
    padding: 0 1.5rem 1.5rem 1.5rem !important;
  }
  .reply-text {
    width: 100%;
  }
}
</style>
